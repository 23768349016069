<template>
  <div>
    <b-row align-h="center" align-v="center" class="full-height  login-container mb-0">
      <b-col cols="3">
        <b-card class="mb-2">
          <div class="header" style="display: inline-flex">
            <img style="margin: 0 auto" src="@/assets/c22-logo.svg" alt="" class="img-to-fit" width="50%">
          </div>
          <b-card-text>
            <b-row class="mb-0 px-0">
              <b-col cols="12" class="px-0 mt-2">
                <b-form-group id="email-input-group" label="Email Address" label-for="email">
                  <b-form-input
                      id="email"
                      name="email"
                      v-model="$v.user.email.$model"
                      :state="validateState('email')"
                      aria-describedby="email-live-feedback"
                  ></b-form-input>

                  <b-form-invalid-feedback
                      id="email-live-feedback"
                  >{{ this.invalidMesg('email') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-0 px-0">
              <b-col cols="12" class="px-0 mt-2">
                <b-form-group id="company-input-group" label="Password" label-for="password">
                  <b-form-input
                      id="password"
                      name="password"
                      v-model="$v.user.password.$model"
                      :state="validateState('password')"
                      aria-describedby="password-live-feedback"
                      type="password"
                  ></b-form-input>

                  <b-form-invalid-feedback
                      id="password-live-feedback"
                  >{{ this.invalidMesg('password') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-0 px-0">
              <b-col cols="12" class="px-0 mt-2">
                <b-form-group id="passwordConfirm-input-group" label="Password Confirm" label-for="passwordConfirm">
                  <b-form-input
                      id="passwordConfirm"
                      name="passwordConfirm"
                      v-model="$v.user.passwordConfirm.$model"
                      :state="validateState('passwordConfirm')"
                      aria-describedby="passwordConfirm-live-feedback"
                      type="password"
                  ></b-form-input>

                  <b-form-invalid-feedback
                      id="passwordConfirm-live-feedback"
                  >{{ this.invalidMesg('passwordConfirm') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row align-h="center" class="mx-0 mb-0">
              <b-button class="mt-3 primary" @click="reset()" size="sm" variant="outline-primary" block squared>
                <b-spinner small v-if="isLoading"></b-spinner>&nbsp;
                Change Password
              </b-button>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>

</template>

<script>

import api from "../../api";
import {validationMixin} from "vuelidate";
import {email, maxLength, minLength, required, sameAs} from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  data: () => ({
    state: 'login',
    showErrors: false,
    errors: [],
    user: {
      name: "",
      surname: "",
      email: "",
      password: "",
      passwordConfirm: "",
    },
    isLoading: false
  }),
  validations: {
    user: {
      name: {
        required,
        minLength: minLength(3)
      },
      surname: {
        required,
        minLength: minLength(3)
      },
      email: {
        required,
        email,

      },


      password: {
        required,
        valid: function (value) {
          const containsUppercase = /[A-Z]/.test(value)
          const containsLowercase = /[a-z]/.test(value)
          const containsNumber = /[0-9]/.test(value)
          const containsSpecial = /[#?!@$%^&*-]/.test(value)
          return containsUppercase && containsLowercase && containsNumber && containsSpecial
        },
        minLength: minLength(9),
        maxLength: maxLength(19)
      },
      passwordConfirm: {
        required,
        sameAsPassword: sameAs("password")
      },
    }
  },

  methods: {
    makeToast(title, message, variant = null) {

      this.$bvToast.toast(`${message}`, {
        title: title,
        variant: variant,
        autoHideDelay: 5000,
        appendToast: false,
        noCloseButton: true
      })
    },
    validateState(name) {
      // console.log(name)
      // console.log(this.$v.user[name])
      const {$dirty, $error} = this.$v.user[name];
      return $dirty ? !$error : null;
    },
    invalidMesg(name) {
      //Returns appropriate error message for end user.
      switch (name) {
        case "name": {
          return "This is a required field and must be at least 3 characters."
        }
        case "surname": {
          return "This is a required field and must be at least 3 characters."
        }
        case "email": {
          return "This is a required field and must be a valid Email address."
        }

        case "password": {
          return "This is a required field and must be at least 9 characters, must contain an Upper case and lower case letter and at lease one special character."
        }
        case "passwordConfirm": {
          return "Passwords no not Match."
        }

      }
    },
    reset() {
      this.isLoading = true;

      const s = () => {
        // console.log(data);
        this.makeToast("Success:", "Your password has been reset.", 'info')
        this.isLoading = false;
        window.location = "/"
      }

      const e = (error) => {
        // console.error(error);
        this.makeToast("An Error has occured:", error, 'danger')
        this.isLoading = false;
      }
      var token = this.$route.query.token

      token = token.replace(/\s/g,"+")

      api.FinishResetPassword({"token":token, "email":this.user.email, "password":this.user.password}, s, e)
    }
  },
}
</script>
<style lang="scss">

.login-container {
  height: 100vh;
  background-repeat: no-repeat;
  background: transparent;

  .card {
    width: 100%;
  }

  .card .header {
    display: inline-flex;
  }

}
</style>