<template>
  <div>
    <div class="navigation">
      <nav>
        <b-img id="logo" :src="require('../assets/c22-logo.svg')"></b-img>
        <a href="https://c22.co.uk">C22</a>
        |
        <router-link to="/candidate">Candidate</router-link>
        |
        <a class="sign-out" @click="logout">
          <b-icon-power></b-icon-power>
          Logout

        </a>
      </nav>
    </div>
    <div class="base" v-if="Loading">
      <b-row class="h-100" v-if="Loading" align-h="center">
        <b-spinner variant="secondary"></b-spinner>
      </b-row>
    </div>
    <b-row v-if="candidateView && !Loading">
      <div class="base">
        <div class="card">
          <label for="CandidateSearch">Candidate Number</label>
          <b-input-group>
            <b-input type="text" id="CandidateSearch" placeholder="Candidate Number" v-model="CandidateId"></b-input>
            <b-input-group-append>
              <b-button id="CandidateSearchButton" @click="SearchCandidate2">
                <b-icon icon="search"></b-icon>
                Search
              </b-button>
            </b-input-group-append>

          </b-input-group>

          <!--    <Candidate v-if="Questionnaire!==null" :Candidate="Candidate" :referee-list="Referees"-->
          <!--               :questionnaires="Questionnaire" @sendEmail="SendEmail">-->

          <!--    </Candidate>-->
        </div>
        <!--        <div v-if="Searching">-->
        <!--          <b-row class="h-100" v-if="Searching" align-h="center">-->
        <!--            <b-spinner variant="secondary"></b-spinner>-->
        <!--          </b-row>-->
        <!--        </div>-->
        <div v-if="Candidate !== null">
          <div class="card">
            <b-row>

              <b-col>
                <b-img class="profile" thumbnail fluid rounded
                  :src="Candidate.profilePhoto ?? 'https://riverlegacy.org/wp-content/uploads/2021/07/blank-profile-photo.jpeg'"
                  alt="Profile Photo"></b-img>

              </b-col>
              <b-col class="mt-1">
                <H1>{{ this.Candidate.firstName }} {{ this.Candidate.lastName }}</H1>
                <p>{{ this.Candidate.email }}</p>
              </b-col>
            </b-row>
          </div>
          <b-row cols="2">
            <b-col v-if="Referees[0] !== undefined">
              <div class="card" style="padding-bottom: 20px">
                <h1>Referee 1</h1>
                <p>Name: {{ this.Referees[0].name }}</p>
                <p>Company: {{ this.Referees[0].company }}</p>
                <p>Job Title: {{ this.Referees[0].jobTitle }}</p>
                <p>Email: {{ this.Referees[0].email }}</p>
                <p>Phone: {{ this.Referees[0].phone }}</p>
                <p>Reference Type: {{ this.Referees[0].referenceType }}</p>
                <p v-if="(this.GetRefereeQuestionnaire(this.Referees[0])).outstanding === undefined">Reference check Not
                  Sent</p>
                <p v-if="(this.GetRefereeQuestionnaire(this.Referees[0])).outstanding !== undefined">Reference check
                  Outstanding:
                  {{ (this.GetRefereeQuestionnaire(this.Referees[0])).outstanding }}</p>
                <b-button v-if="((this.GetRefereeQuestionnaire(this.Referees[0])).outstanding ?? true)"
                  @click="SendEmail(Candidate.id, Referees[0])" :disabled="sending1">
                  <b-spinner small v-if="sending1" variant="primary"></b-spinner>
                  &nbsp;{{ (this.GetRefereeQuestionnaire(this.Referees[0])).outstanding === undefined ? "Send Email" :
                    "Resend Email" }}
                </b-button>
                <b-button v-if="!((this.GetRefereeQuestionnaire(this.Referees[0])).outstanding ?? true)"
                  @click="ViewQuestionnaire(Referees[0])">View Reference check
                </b-button>

              </div>
            </b-col>
            <b-col v-if="Referees[1] !== undefined">
              <div class="card" style="padding-bottom: 20px">
                <h1>Referee 2</h1>
                <p>Name: {{ this.Referees[1].name }}</p>
                <p>Company: {{ this.Referees[1].company }}</p>
                <p>Job Title {{ this.Referees[1].jobTitle }}</p>
                <p>Email: {{ this.Referees[1].email }}</p>
                <p>Phone: {{ this.Referees[1].phone }}</p>
                <p>Reference Type: {{ this.Referees[1].referenceType }}</p>
                <p v-if="(this.GetRefereeQuestionnaire(this.Referees[1])).outstanding === undefined">Reference check Not
                  Sent</p>
                <p v-if="(this.GetRefereeQuestionnaire(this.Referees[1])).outstanding !== undefined">Reference check
                  Outstanding:
                  {{ (this.GetRefereeQuestionnaire(this.Referees[1])).outstanding }}</p>
                <b-button v-if="((this.GetRefereeQuestionnaire(this.Referees[1])).outstanding ?? true)"
                  @click="SendEmail(Candidate.id, Referees[1])" :disabled="sending2">
                  <b-spinner small v-if="sending2" variant="primary"></b-spinner>
                  &nbsp; {{ (this.GetRefereeQuestionnaire(this.Referees[1])).outstanding === undefined ? "Send Email" :
                    "Resend Email" }}
                </b-button>
                <b-button v-if="!((this.GetRefereeQuestionnaire(this.Referees[1])).outstanding ?? true)"
                  @click="ViewQuestionnaire(Referees[1])">View Reference check
                </b-button>

              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-row>
    <b-row v-if="!candidateView && !Loading" ref="questionnaire">
      <div class="base">
        <div class="card questionnaire-box">
          <div>
            <b-button @click="ViewCandidate">Back</b-button>
            <!--            <b-button @click="printPdf">Print</b-button>-->
          </div>
          <!--          <div v-if="QuestionnaireLink===null">-->
          <!--            <h2> This Questionnaire does not exist</h2>-->
          <!--          </div>-->
          <div v-if="QuestionnaireLink !== null">
            <div v-if="!QuestionnaireLink.outstanding">
              <RefereeDetailsFull :referee="this.Referee"></RefereeDetailsFull>

              <WorkQuestionnaire v-if="QuestionnaireLink.questionnaireType === 0" :Questionnaire="this.Questionnaire"
                :QuestionnaireId="ReferalCode" :questionnaire-link="QuestionnaireLink" :view="true">
              </WorkQuestionnaire>
              <PersonalQuestionnaire v-if="QuestionnaireLink.questionnaireType === 1" :Questionnaire="this.Questionnaire"
                :QuestionnaireId="ReferalCode" :questionnaire-link="QuestionnaireLink" :view="true">
              </PersonalQuestionnaire>
              <AcademicQuestionnaire v-if="QuestionnaireLink.questionnaireType === 2" :Questionnaire="this.Questionnaire"
                :QuestionnaireId="ReferalCode" :questionnaire-link="QuestionnaireLink" :view="true">
              </AcademicQuestionnaire>
            </div>
            <div v-if="QuestionnaireLink.outstanding">
              <H2> This Questionnaire has not been completed.</H2>
            </div>
          </div>

        </div>
      </div>
    </b-row>

  </div>
</template>

<script>
import api from "@/api";
import RefereeDetailsFull from "@/components/RefereeDetailsFull";
import WorkQuestionnaire from "@/components/Questionnaires/WorkQuestionnaire";
import PersonalQuestionnaire from "@/components/Questionnaires/PersonalQuestionnaire";
import AcademicQuestionnaire from "@/components/Questionnaires/AcademicQuestionnaire";

// import Candidate from "@/components/Candidate";

export default {
  name: "CandidateView",
  data() {
    return {
      CandidateId: "",

      Candidate: null,
      Referees: null,
      RefereeQuestionnaire: null,
      Questionnaire: null,
      Searching: false,
      sending1: false,
      sending2: false,
      candidateView: true,
      QuestionnaireLink: null,
      CandidateQuestionnaire: null,
      ReferalCode: "",
      Loading: false,
      Referee: {
        Name: String,
        Company: String,
        JobTitle: String,
        Email: String,
        Phone: String,
        ReferenceType: String,
        Pos: Number
      },
      user_email: "",
    }
  },
  components: {
    RefereeDetailsFull,
    WorkQuestionnaire, PersonalQuestionnaire, AcademicQuestionnaire
  },
  mounted() {
    this.user_email = localStorage.getItem('user_email'); // Retrieve user email from local storage
  },
  methods: {
    logout() {
      localStorage.clear();
      this.$router.push({ path: '/login' });

    },
    GetQuestionnaireLink() {
      this.Loading = true;
      this.QuestionnaireLink = null;
      this.Referee = null;
      this.Questionnaire = null
      api.GetQuestionnaireLink(this.ReferalCode, (x) => {
        api.GetCandidateRefereeByPos(x.candidateId, x.refereePos, y => {
          api.GetQuestionnaire(this.ReferalCode, (z) => {
            this.QuestionnaireLink = x;
            this.Referee = y;
            this.Questionnaire = z
            this.Loading = false
          }, () => { this.makeToast("Candidate", "Failed to get Candidate Reference check", 'warning') })

        }, () => { this.makeToast("Candidate", "Failed to get Candidate Reference check", 'warning') })

      }, () => { this.makeToast("Candidate", "Failed to get Candidate Reference check", 'warning') });
    },
    SearchCandidate2() {
      // this.$router.push({name:"Candidate2",query: {id: this.CandidateId} })
      this.Candidate = null
      this.Referees = []
      this.Loading = true
      api.GetCandidateInfo(this.CandidateId, x => {
        this.Candidate = x;
        this.Loading = false
      }, () => {
        this.makeToast("Candidate Search", "Could not find candidate with ID: " + this.CandidateId, 'warning')
        this.Loading = false
      })

      api.GetCandidateReferees(this.CandidateId, x => {
        this.Referees = x;

      }, () => { this.makeToast("Candidate", "Failed to update Candidate Referees", 'warning') })

      api.GetCandidateQuestionnaires(this.CandidateId, x => {
        this.CandidateQuestionnaire = x


      }, () => { this.makeToast("Candidate", "Failed to update Candidate Reference check", 'warning') })

    },
    ViewCandidate() {

      this.candidateView = true
    },
    SendEmail(CandidateId, referee) {

      console.log(referee);
      if (referee.pos === 1) {
        this.sending1 = true;
      } else {
        this.sending2 = true;
      }

      var questionnaireCreate = { candidateId: CandidateId, referee: referee, consultantEmail: this.user_email };

      api.CreateQuestionnaire(questionnaireCreate, (response) => {
        api.SendQuestionnaireLink(response.questionnaireId, () => {
          this.makeToast("Referee Email", "Email Sent", 'info');
          if (referee.pos === 1) {
            this.sending1 = false;
          } else {
            this.sending2 = false;
          }
          api.GetCandidateQuestionnaires(this.CandidateId, (x) => {
            this.CandidateQuestionnaire = x;
          }, () => {
            this.makeToast("Candidate", "Failed to update Candidate Reference check", 'warning');
          });
        }, () => {
          if (referee.pos === 1) {
            this.sending1 = false;
          } else {
            this.sending2 = false;
          }
          this.makeToast("Referee Email", "Email Failed", 'warning');
        });
      }, console.error);
    },
    // SendEmail(CandidateId, referee) {

    //   console.log(referee)
    //   if (referee.pos === 1) {
    //     this.sending1 = true
    //   } else {
    //     this.sending2 = true
    //   }

    //   var questionnaireCreate = {candidateId: CandidateId, referee: referee}

    //   api.CreateQuestionnaire(questionnaireCreate, x => {

    //     api.SendQuestionnaireLink(x.questionnaireId, () => {
    //       this.makeToast("Referee Email", "Email Sent", 'info')
    //       if (referee.pos === 1) {
    //         this.sending1 = false

    //       } else {
    //         this.sending2 = false
    //       }
    //       api.GetCandidateQuestionnaires(this.CandidateId, x => {
    //         this.CandidateQuestionnaire = x

    //       }, ()=>{this.makeToast("Candidate", "Failed to update Candidate Reference check", 'warning')})

    //     }, () => {
    //       if (referee.pos === 1) {
    //         this.sending1 = false
    //       } else {
    //         this.sending2 = false
    //       }
    //       this.makeToast("Referee Email", "Email Failed", 'warning')
    //     })
    //   }, console.error)
    // },
    // ResendEmail(CandidateId) {
    //   api.SendQuestionnaireLink(CandidateId, console.log, console.error)
    // },
    GetRefereeQuestionnaire(referee) {
      var question = this.CandidateQuestionnaire.find(x => {
        return x.refereeFullName.toUpperCase() === referee.name.toUpperCase()
      }
      )
      if (question === undefined) {
        return {}
      }

      return question
    },
    makeToast(title, message, variant = null) {

      this.$bvToast.toast(`${message}`, {
        title: title,
        variant: variant,
        autoHideDelay: 5000,
        appendToast: false,
        noCloseButton: true
      })
    },
    ViewQuestionnaire(referee) {
      // var QuestionnaireId = this.GetRefereeQuestionnaire(referee).questionnaireId
      this.Referee = referee
      // this.Questionnaire = this.GetRefereeQuestionnaire(referee)
      this.ReferalCode = this.GetRefereeQuestionnaire(referee).questionnaireId
      this.candidateView = false;
      this.GetQuestionnaireLink()
    }
  },
  // created() {

  // }
}
</script>

<style>
div .base {

  padding: 20px 40px;
  width: 800px;
  margin: 100px auto 0;
}

.navigation {
  top: 0px;
  position: absolute;
  width: 100%;
}

.card {
  padding: 10px 20px;
  background-color: #C0C9D3;
}

.input-group-append {
  padding: 0px;

}



#CandidateSearchButton {

  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>