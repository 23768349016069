<template>
  <div>
    <b-row align-h="center" align-v="center" class="full-height  login-container mb-0 flex-row">
      <b-col class="col-3">
        <b-card class="mb-2">
          <div class="header" style="display: inline-flex">
            <img style="margin: 0 auto" src="@/assets/c22-logo.svg" alt="" class="img-to-fit" width="50%">
          </div>
          <b-card-text>


            <b-row class="mb-0 px-0">
              <b-col cols="12" class="px-0 mt-2">
                <b-form-group id="email-input-group" label="Email Address" label-for="email">
                  <b-form-input
                      id="email"
                      name="email"
                      v-model="$v.user.email.$model"
                      :state="validateState('email')"
                      aria-describedby="email-live-feedback"
                  ></b-form-input>

                  <b-form-invalid-feedback
                      id="email-live-feedback"
                  >{{ this.invalidMesg('email') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-0 px-0" >
              <b-col cols="12" class="px-0 mt-2">
                <b-form-group id="company-input-group" label="Password" label-for="password">
                  <b-form-input
                      id="password"
                      name="password"
                      v-model="$v.user.password.$model"
                      :state="validateState('password')"
                      aria-describedby="password-live-feedback"
                      type="password"
                  ></b-form-input>

                  <b-form-invalid-feedback
                      id="password-live-feedback"
                  >{{ this.invalidMesg('password') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row align-h="center" class="mx-0 mb-0">
              <b-button class="mt-3" @click="loginUser()" :variant="filled">
                <b-spinner small v-if="isLoading"></b-spinner>
                &nbsp;Login
              </b-button>
            </b-row>
            <b-row class="mx-0">

              <b-button class="mt-3 primary-no-border" @click="forgotPassword()" variant="outline-primary"
                        style="background-color: transparent; color: #222222">
                Forgot Password?
              </b-button>

              <b-button class="mt-3 primary-no-border" @click="register()" variant="outline-primary"
                        style="background-color: transparent; color: #222222">
                Register
              </b-button>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import api from "../../api";
import {validationMixin} from "vuelidate";
import {email, maxLength, minLength, required, sameAs} from "vuelidate/lib/validators";


export default {
  name: "LoginScreen",
  mixins: [validationMixin],
  components: {},
  validations: {
    user: {
      name: {
        required,
        minLength: minLength(3)
      },
      surname: {
        required,
        minLength: minLength(3)
      },
      email: {
        required,
        email,

      },


      password: {
        required,
        valid: function (value) {
          const containsUppercase = /[A-Z]/.test(value)
          const containsLowercase = /[a-z]/.test(value)
          const containsNumber = /[0-9]/.test(value)
          const containsSpecial = /[#?!@$%^&*-]/.test(value)
          return containsUppercase && containsLowercase && containsNumber && containsSpecial
        },
        minLength: minLength(9),
        maxLength: maxLength(19)
      },
      passwordConfirm: {
        required,
        sameAsPassword: sameAs("password")
      },
    }
  },
  data: () => ({

    showErrors: false,
    errors: [],
    user: {
      name: "",
      surname: "",
      email: "",
      password: "",
      passwordConfirm: "",
    },
    isLoading: false
  }),
  mounted() {

  },

  methods: {
    validateState(name) {
      // console.log(name)
      // console.log(this.$v.user[name])
      const {$dirty, $error} = this.$v.user[name];
      return $dirty ? !$error : null;
    },
    invalidMesg(name) {
      //Returns appropriate error message for end user.
      switch (name) {
        case "name": {
          return "This is a required field and must be at least 3 characters."
        }
        case "surname": {
          return "This is a required field and must be at least 3 characters."
        }
        case "email": {
          return "This is a required field and must be a valid Email address."
        }

        case "password": {
          return "This is a required field and must be at least 9 characters, must contain an Upper case and lower case letter and at lease one special character."
        }
        case "passwordConfirm": {
          return "Passwords no not Match."
        }

      }
    },
    forgotPassword() {
      this.$router.push({path: '/forgot'})
    },
    register() {
      this.$router.push({path: '/Register'})
    },
    loginUser() {
      this.isLoading = true;
      const s = (data) => {
        this.userData = data;
        console.log("this.userdata",this.userData);

        // This needs to be adapted to take in the new jwt token and get these values from it.

        // Current data returned from server: {"token":"jwt-token","expiration":"2022-04-11T13:38:12Z"}

        var base64Url = data.token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        var currentData = JSON.parse(jsonPayload);
        localStorage.setItem("api_Key", data.token);
        localStorage.setItem("user_data", JSON.stringify(currentData));
        localStorage.setItem("user_email", this.user.email);

        window.location = '/'

      };

      const e = (msg) => {

        if (typeof msg !== 'string') {
          if (msg.errors.Email != null && msg.errors.Email.length > 0) {
            this.makeToast("An Error has occured:", msg.errors.Email[0], 'danger');
            this.isLoading = false;
            return;
          }

          if (msg.errors.Password != null && msg.errors.Password.length > 0) {
            this.makeToast("An Error has occured:", msg.errors.Password[0], 'danger');
            this.isLoading = false;
            return;
          }
        }

        this.makeToast("An Error has occured:", msg, 'danger');
        this.isLoading = false;
      };

      api.getAuthLogin(this.user.email, this.user.password, s, e);
    },

    makeToast(title, message, variant = null) {
      this.$bvToast.toast(`${message}`, {
        title: title,
        variant: variant,
        autoHideDelay: 5000,
        appendToast: false,
        noCloseButton: true
      })
    },

    hideVerificationEmailModal() {
      this.$refs['email-verification-modal'].hide()
    },
    showVerificationEmailModal() {
      this.$refs['email-verification-modal'].show()
    },
  },
  computed: {

    filled() {
      if (this.user.email !== null && this.user.password !== null && this.user.email !== '' && this.user.password !== '') {
        return 'primary'
      } else {
        return 'outline-primary'
      }
    }
  }
}
</script>
<style lang="scss">


.login-container {
  height: 100vh;
  background-repeat: no-repeat;
  background: transparent;

  .card {
    width: 100%;
  }

  .card .header {
    display: inline-flex;
  }

}
</style>
