import Vue from 'vue'
import VueRouter from 'vue-router'
import Questionnaire from "../views/Questionnaire";
import CandidateView from "../views/CandidateView";
import QuestionnaireViewer from "@/views/QuestionnaireViewer";
import Login from "@/views/Login/Login";
import ForgotPassword from "@/views/Login/ForgotPassword";
import ResetPassword from "@/views/Login/ResetPassword";
import Register from "@/views/Login/Register";

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/forgot',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/reset',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/',
    redirect:'/candidate'

  },
  {
    path: '/candidate',
    name: 'Candidate',
    component: CandidateView
  },
  {
    path: '/candidate/:candidateId',
    name: 'Candidate',
    component: CandidateView
  },
  {
    path: '/candidate/questionnaire/:referalCode',
    name: 'CandidateQuestionnaireViewer',
    component: QuestionnaireViewer

  },
  {
    path: '/questionnaire/:referalCode',
    name: 'Questionnaire',
    component: Questionnaire,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/css/*',
    name: 'css',   
    meta: {
      requiresAuth: false
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  const token = JSON.parse(localStorage.getItem('user_data'))
  let expired = false

  if (token != null) {

    expired = token.exp < Date.now() / 1000
  }
  else{
    expired = true
  }

  if (expired) {
    localStorage.clear()
  }


   console.log('To Name:' + to.name)
   
  // If logged in, or going to the Login page.
  //exclusive or - If token exists exclusively continue to next page - If Token doesnt exist and going to the following pages continue
  var fullopen = (to.name === 'Questionnaire')
  var bContinue = ( (to.name === 'Register'  || to.name === 'ResetPassword'|| to.name === 'ForgotPassword'||to.name==='Login') && expired ) || ( !(to.name === 'Register'  || to.name === 'ResetPassword'|| to.name === 'ForgotPassword'||to.name==='Login') && !expired )
  var bHome = !expired && (to.name === 'Register'  || to.name === 'ResetPassword'|| to.name === 'ForgotPassword'||to.name==='Login' || to.name === 'Questionnaire'  )
  var bLogin = !(!expired && to.name === 'Register'  && to.name === 'ResetPassword'&& to.name === 'ForgotPassword') || (expired && to.name==='Login')
  if (fullopen || bContinue) {
    next()

  } else if (bHome) {
    // Continue to page.
    next({name:'Candidate'})

  } else if (bLogin){
    // Not logged in, redirect to login.
    next({name: 'Login'})
  }
  //else
    //next()


});

export default router
