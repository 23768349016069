import Vue from 'vue'
import App from './App.vue'
import router from './router'



import { BootstrapVue, IconsPlugin, ToastPlugin} from 'bootstrap-vue'

import "./components/Questionnaires/WorkQuestionnaire"
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './style.scss'
import api from "./api";


Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(ToastPlugin)


fetch('/config.json')
    .then(res => res.json())
    .then(config => {
        api.Url = config.api_url,
new Vue({
  data: () => ({
      urlConfig : config
  }),
  router,
  render: h => h(App)
}).$mount('#app')
    })
