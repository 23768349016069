const axios = require('axios');


let authGetInstance = axios.create({
    method: "get",
    headers:
        {
            Authorization: `Bearer ${localStorage.getItem("api_Key")}`
        }
});

let authPostInstance = axios.create({
    method: "post",
    headers:
        {
            Authorization: `Bearer ${localStorage.getItem("api_Key")}`,
            'Content-Type': 'application/json'
        }
});

// let nonAuthGetInstance = axios.create({
//     method: "get"
// });

let nonAuthPostInstance = axios.create({
    method: "post",
    headers:
        {
            // 'Content-Type': 'application/json'
        }
});

export default {
    Url: localStorage.getItem("api_url"),
    //Auth
    getAuthLogin(email, password, s, e) {
        nonAuthPostInstance({
            url: this.Url + "/Auth/Login",
            data: {
                "email": email,
                "password": password,
            }
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.response.data);
            });
    },
    Register(userDto, s, e) {
        nonAuthPostInstance({
            url: this.Url + "/Auth/Register",
            data: userDto,
            method: "post",
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.response.data);
            });
    },
    resetPassword(email, s, e) {
        nonAuthPostInstance({
            url: this.Url + "/Auth/ResetPassword?email=" + email
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.response.data);
            });
    },

    FinishResetPassword(model, s, e) {
        nonAuthPostInstance({
                url: this.Url + "/Auth/FinishResetPassword",
                data: model
            }
        )
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.response.data);
            });
    },

    //Candidate
    GetCandidateInfo(candidateId, s, e) {
        authGetInstance({
            url: this.Url + "/Candidate/" + candidateId
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to get Candidate");
            });
    },
    GetCandidateReferees(candidateId, s, e) {
        authGetInstance({
            url: this.Url + "/Candidate/" + candidateId+"/Referees"
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to get Candidate Referees");
            });
    },
    GetCandidateRefereeByPos(candidateId,refereePos, s, e) {
        authGetInstance({
            url: this.Url + "/Candidate/" + candidateId+"/Referees/"+refereePos
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to get Candidate Referee at Pos");
            });
    },
    GetCandidateQuestionnaires(candidateId, s, e) {
        authGetInstance({
            url: this.Url + "/Candidate/" + candidateId+"/Questionnaires"
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to get Candidate Questionnaires");
            });
    },

    //Questionnaire
    GetQuestionnaireLink(questionnaireId, s, e) {
        authGetInstance({
            url: this.Url + "/Questionnaire/" + questionnaireId+"/link"
        })
            .then(function (resp) {

                s(resp.data);

            })
            .catch(function (error) {
                console.error(error);
                e("Failed to get questionnaire info by Questionnaire ID");
            });
    },
    GetQuestionnaire(questionnaireId, s, e) {

        authGetInstance({
            url: this.Url + "/Questionnaire/" + questionnaireId
        })
            .then(function (resp) {

                s(resp.data);

            })
            .catch(function (error) {
                console.error(error);
                e("Failed to get questionnaire info by Questionnaire ID");
            });
    },
    SubmitQuestionnaire(questionnaire,type, s, e) {

        console.log("SubmitQuestionnaire:" + questionnaire + "/"+type)

       nonAuthPostInstance({
            url: this.Url + "/Questionnaire/submit/"+type,
            data: questionnaire
        })
            .then(function (resp) {

                s(resp.data);
            })
            .catch(function (error) {

                console.error(error);
                e("Failed to submit Questionnaire");
            });

    },
    CreateQuestionnaire(questionnaireLinkData, s, e) {      
        authPostInstance({
          url: this.Url + "/Questionnaire/create",
          data: questionnaireLinkData
        })
          .then(function(resp) {
            s(resp.data);
          })
          .catch(function(error) {
            console.error(error);
            e("Failed to submit Questionnaire");
          });
      },
    SendQuestionnaireLink(questionnaireId, s, e) {

        authGetInstance({
            url: this.Url + "/Questionnaire/send?questionnaireId="+questionnaireId,
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to Send Questionnaire Link");
            });
    },

}