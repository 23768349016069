<template>
  <div class="questionnaire">
    <!--    @submit="onSubmit" @reset="onReset"-->
    <b-form v-if="!completed" @submit.stop.prevent="onSubmit">
      <b-form-group
          id="Relationship-group"
          :label="'Please describe your relationship to '+ QuestionnaireLink.candidateFullName+'?'"
          label-for="Relationship"
          description="">
        <b-form-input
            id="Relationship"
            v-model="QuestionnaireValue.relationship"
            @input='updateValue'
            type="text"
            placeholder="Enter your relationship"
            :disabled="view"
        ></b-form-input>
      </b-form-group>
      <b-form-group
          id="DurationKnown-group"
          :label="'How long have you known '+QuestionnaireLink.candidateFullName+'?'"
          label-for="DurationKnown"
          description="">
        <b-form-input
            id="DurationKnown"
            v-model="QuestionnaireValue.durationKnown"
            @input='updateValue'
            type="text"
            placeholder="Enter Duration"
            required
            :disabled="view"
        ></b-form-input>
      </b-form-group>
      <b-form-group
          id="QualityCandidateSkills-group"
          :label="'What skills does '+ QuestionnaireLink.candidateFullName+' have that you believe make them stand out as a quality candidate?'"
          label-for="QualityCandidateSkills"
          description="">
        <b-form-input
            id="QualityCandidateSkills"
            v-model="QuestionnaireValue.qualityCandidateSkills"
            @input='updateValue'
            type="text"
            placeholder=""
            required
            :disabled="view"
        ></b-form-input>
      </b-form-group>
      <b-form-group
          id="Strengths-group"
          :label="'Describe '+QuestionnaireLink.candidateFullName+'s work ethic?'"
          label-for="Strengths"
          description="">
        <b-form-textarea
            id="Strengths"
            v-model="QuestionnaireValue.workEthic"
            @input='updateValue'
            rows="3"
            max-rows="5"
            :placeholder="QuestionnaireLink.candidateFullName+'s work ethic'"
            :disabled="view"
        ></b-form-textarea>
      </b-form-group>

      <b-form-group
          id="AcademicAchievements-group"
          :label="QuestionnaireLink.candidateFullName+'`s Academic Achievements'"
          label-for="AcademicAchievements"
          description="">
        <b-form-textarea
            id="AcademicAchievements"
            v-model="QuestionnaireValue.academicAchievements"
            @input='updateValue'
            rows="3"
            max-rows="5"
            :placeholder="'Enter '+QuestionnaireLink.candidateFullName+'`s Academic Achievements'"
            :disabled="view"
        ></b-form-textarea>
      </b-form-group>
      <b-button
          type="submit"
          class="submit-user-btn"

          v-if="!view"
      >
        <b-spinner small v-if="loading" variant="primary"></b-spinner>&nbsp;Submit
      </b-button>
    </b-form>
    <div>
      <b-card v-if="completed">
        <b-card-title>Reference check Completed</b-card-title>
        <b-card-body>Thank you for completing the Reference check</b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "AcademicQuestionnaire",
  props: {
    Questionnaire: {
      generalConduct: Number,
      durationKnown: String
    },

    QuestionnaireLink:{},
    view:Boolean,

  },

  data() {
    return {
      QuestionnaireId: String,
      QuestionnaireValue: {
        generalConduct: null,
        durationKnown: null
      },
      completed:false,
      loading:false
    }
  },
  methods: {

    onSubmit() {
      this.loading = true
      this.QuestionnaireValue.id = this.QuestionnaireId

      api.SubmitQuestionnaire(this.QuestionnaireValue, "academic", () => {
        this.makeToast("Reference check", "Reference check Submitted", "info")
        this.completed = true
        this.loading=false
      }, () => {
        this.loading=false
        this.makeToast("Reference check", "Reference check submission Failed", "warning")
      })

    },
    onReset() {

    },
    updateValue() {
      this.$emit('update:modelValue', this.QuestionnaireValue);
    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(`${message}`, {
        title: title,
        variant: variant,
        autoHideDelay: 5000,
        appendToast: false,
        noCloseButton: true
      })
    },
  },
  created() {
    this.QuestionnaireId = this.$route.params.referalCode;
    if (this.view===true){
      this.QuestionnaireId = this.QuestionnaireLink.questionnaireId
      api.GetQuestionnaire(this.QuestionnaireId,x=>{
        this.QuestionnaireValue=x
      },() => {

        this.makeToast("Reference check", "Failed to load QuestioReference checknnaire", "warning")
      })
      return
    }
    this.QuestionnaireValue.generalConduct = this.Questionnaire.generalConduct
    this.QuestionnaireValue.durationKnown = this.Questionnaire.durationKnown
    this.QuestionnaireValue.willReEmploy=false;

    this.QuestionnaireValue.generalConduct = "";
    this.QuestionnaireValue.workPerformance = "";
    this.QuestionnaireValue.attitudeAtWork =  "";
    this.QuestionnaireValue.initiative =  "";
    this.QuestionnaireValue.timeKeeping =  "";
    this.QuestionnaireValue.relationshipWithColleagues =  "";
    this.QuestionnaireValue.relationshipWithCustomers =  "";
    this.QuestionnaireValue.id = this.QuestionnaireId;

  }
}
</script>
<style>
output {
  border: none !important;
  margin: 2px 5px;
  width: 197px !important;
}
</style>