<template>
  <div>
    <b-row align-h="center" align-v="center" class="full-height  login-container mb-0">
      <b-col cols="3">
        <b-card class="mb-2">
          <div class="header" style="display: inline-flex">
            <img style="margin: 0 auto" src="@/assets/c22-logo.svg" alt="" class="img-to-fit" width="50%">
          </div>
          <b-card-text>
            <b-form @submit.stop.prevent="onSubmit">
              <b-row class="mb-0 px-0">
                <b-col cols="12" class="px-0 mt-2">
                  <b-form-group id="email-input-group">
                    <b-form-input
                        id="email"
                        name="email"
                        v-model="$v.user.email.$model"
                        :state="validateState('email')"
                        aria-describedby="email-live-feedback"
                        placeholder="Email Address"
                    ></b-form-input>

                    <b-form-invalid-feedback
                        id="email-live-feedback"
                    >{{ this.invalidMesg('email') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row align-h="center" class="mx-0 mb-0">
                <b-button class="mt-3 primary" @click="reset()" size="sm" variant="outline-primary" block squared>
                  <b-spinner small v-if="isLoading"></b-spinner>&nbsp;
                  Send Reset Password
                </b-button>
              </b-row>
            </b-form>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>

</template>
<style lang="scss">

.login-container {
  height: 100vh;
  background: transparent no-repeat;

  .card {
    width: 100%;
  }

  .card .header {
    display: inline-flex;
  }

}
</style>
<script>

import api from "../../api";
import {email, maxLength, minLength, required, sameAs} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  mixins: [validationMixin],
  components: {},
  data: () => ({
    showErrors: false,
    errors: [],
    // email: null,
    user:{
      email:null
    },
    password: null,
    isLoading: false
  }),
  validations: {
    user: {
      email: {
        required,
        email,

      },


      password: {
        required,
        valid: function (value) {
          const containsUppercase = /[A-Z]/.test(value)
          const containsLowercase = /[a-z]/.test(value)
          const containsNumber = /[0-9]/.test(value)
          const containsSpecial = /[#?!@$%^&*-]/.test(value)
          return containsUppercase && containsLowercase && containsNumber && containsSpecial
        },
        minLength: minLength(9),
        maxLength: maxLength(19)
      },
      passwordConfirm: {
        required,
        sameAsPassword: sameAs("password")
      },
    }
  },
  methods: {
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(`${message}`, {
        title: title,
        variant: variant,
        autoHideDelay: 5000,
        appendToast: false,
        noCloseButton: true
      })
    },
    validateState(name) {
      // console.log(name)
      // console.log(this.$v.user[name])
      const {$dirty, $error} = this.$v.user[name];
      return $dirty ? !$error : null;
    },
    invalidMesg(name) {
      //Returns appropriate error message for end user.
      switch (name) {
        case "name": {
          return "This is a required field and must be at least 3 characters."
        }
        case "surname": {
          return "This is a required field and must be at least 3 characters."
        }
        case "email": {
          return "This is a required field and must be a valid Email address."
        }

        case "password": {
          return "This is a required field and must be at least 9 characters, must contain an Upper case and lower case letter and at lease one special character."
        }
        case "passwordConfirm": {
          return "Passwords no not Match."
        }

      }
    },
    reset() {
      this.isLoading = true;

      const s = () => {
        // console.log(data)
        this.makeToast("Forgot Password:", "An Email with the link has been sent.", 'info')

        this.isLoading = false;

      };

      const e = (error) => {
        console.error(error);
        this.makeToast("An Error has occurred:", error, 'danger')
        this.isLoading = false;
      };

      api.resetPassword(this.user.email, s, e)
    }
  },
  computed: {}
}
</script>

