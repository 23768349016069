<template>
<div class="base">
  <div class="card questionnaire-box">
    <div v-if="NotExist">
      <h2> This Reference check does not exist</h2>
    </div>
    <div v-if="QuestionnaireLink!==null">
      <div v-if="QuestionnaireLink.outstanding===true" >
        <h2 class="mt-2">Good day {{ this.QuestionnaireLink["refereeFullName"] }},</h2>
        <h1>Thank you for filling in this Reference check regarding {{ this.QuestionnaireLink.candidateFullName }}</h1>
        <RefereeDetailsFull :referee="this.Referee"></RefereeDetailsFull>
        <WorkQuestionnaire v-if="QuestionnaireLink.questionnaireType===0"
                           :Questionnaire="this.Questionnaire"
                           :QuestionnaireId="ReferalCode"
                           :questionnaire-link="QuestionnaireLink"
                           :view="false"
        >
        </WorkQuestionnaire>
        <PersonalQuestionnaire v-if="QuestionnaireLink.questionnaireType===1" :Questionnaire="this.Questionnaire" :QuestionnaireId="ReferalCode" :questionnaire-link="QuestionnaireLink" :view="false">
        </PersonalQuestionnaire>
        <AcademicQuestionnaire v-if="QuestionnaireLink.questionnaireType===2" :Questionnaire="this.Questionnaire" :QuestionnaireId="ReferalCode" :questionnaire-link="QuestionnaireLink" :view="false">
        </AcademicQuestionnaire>
      </div>
      <div v-if="QuestionnaireLink.outstanding===false">
        <H2> This Reference check has already been filled.</H2>
      </div>
    </div>

  </div>
  </div>
</template>

<script>

import api from "@/api";
import WorkQuestionnaire from "@/components/Questionnaires/WorkQuestionnaire";
import PersonalQuestionnaire from "@/components/Questionnaires/PersonalQuestionnaire";
import AcademicQuestionnaire from "@/components/Questionnaires/AcademicQuestionnaire";
import RefereeDetailsFull from "@/components/RefereeDetailsFull";
export default {
  name: "QuestionnaireView",
  components:{
    RefereeDetailsFull,
    WorkQuestionnaire,PersonalQuestionnaire,AcademicQuestionnaire
  },
  data(){
    return{
       QuestionnaireLink:null,
        ReferalCode:this.$route.params.referalCode,
      Questionnaire:{
         Id:this.ReferalCode
      },
      Referee:{
        Name:String,
        Company:String,
        JobTitle:String,
        Email:String,
        Phone:String,
        ReferenceType:String,
        Pos:Number
      },
      NotExist:false

    }
  },
  methods:{
  GetQuestionnaireLink(){

      api.GetQuestionnaireLink(this.ReferalCode,(x)=>{this.QuestionnaireLink=x;
        this.GetQuestionnaireReferee()
        },
          ()=>{
            this.makeToast("Candidate", "Failed to get Candidate Questionnaire", 'warning')
        this.NotExist=true
          });
    },
    GetQuestionnaireReferee(){
      api.GetCandidateRefereeByPos(this.QuestionnaireLink.candidateId,this.QuestionnaireLink.refereePos, x => {
        this.Referee = x;

      }, ()=>{
       this.makeToast("Candidate", "Failed to get Referee Questionnaire", 'warning')
        this.NotExist=true
      })
    },
  },

  created() {
    this.GetQuestionnaireLink();
  }
}
</script>

<style scoped>
.questionnaire-box{
  /*background-color: #C0C9D3;*/
  padding: 0 20px;
  /*border-radius: 50px;*/
}
div .base {

  padding: 20px 40px;
  width: 800px;
  margin: 0 auto;
}
</style>