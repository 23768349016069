<template>
  <div>
    <div class="navigation">
      <nav>
        <b-img id="logo" :src="require('../assets/c22-logo.svg')"></b-img>
        <a href="https://c22.co.uk">C22</a>
        |
        <router-link to="/candidate">Candidate</router-link>
        |
        <a class="sign-out" @click="logout">
          <b-icon-power></b-icon-power>
          Logout

        </a>
      </nav>
    </div>
    <div v-if="Loading">
      <b-row class="h-100" v-if="Loading" align-h="center">
        <b-spinner variant="primary"></b-spinner>
      </b-row>
    </div>

    <b-row>
      <div class="base">
        <div class="card questionnaire-box">
          <div v-if="QuestionnaireLink===null">
            <h2> This Reference check does not exist</h2>
          </div>
          <div v-if="QuestionnaireLink!==null">
            <div v-if="!QuestionnaireLink.outstanding">
              <RefereeDetailsFull :referee="this.Referee"></RefereeDetailsFull>

              <WorkQuestionnaire v-if="QuestionnaireLink.questionnaireType===0"
                                 :Questionnaire="this.Questionnaire"
                                 :QuestionnaireId="ReferalCode"
                                 :questionnaire-link="QuestionnaireLink"
                                 :view="true"
              >
              </WorkQuestionnaire>
              <PersonalQuestionnaire v-if="QuestionnaireLink.questionnaireType===1" :Questionnaire="this.Questionnaire"
                                     :QuestionnaireId="ReferalCode" :questionnaire-link="QuestionnaireLink"
                                     :view="true">
              </PersonalQuestionnaire>
              <AcademicQuestionnaire v-if="QuestionnaireLink.questionnaireType===2" :Questionnaire="this.Questionnaire"
                                     :QuestionnaireId="ReferalCode" :questionnaire-link="QuestionnaireLink"
                                     :view="true">
              </AcademicQuestionnaire>
            </div>
            <div v-if="QuestionnaireLink.outstanding">
              <H2> This Reference check has not been completed.</H2>
            </div>
          </div>

        </div>
      </div>
    </b-row>
  </div>
</template>

<script>

import api from "@/api";
import WorkQuestionnaire from "@/components/Questionnaires/WorkQuestionnaire";
import PersonalQuestionnaire from "@/components/Questionnaires/PersonalQuestionnaire";
import AcademicQuestionnaire from "@/components/Questionnaires/AcademicQuestionnaire";
import RefereeDetailsFull from "@/components/RefereeDetailsFull"

export default {
  name: "QuestionnaireView",
  components: {
    RefereeDetailsFull,
    WorkQuestionnaire, PersonalQuestionnaire, AcademicQuestionnaire
  },
  data() {
    return {
      QuestionnaireLink: null,
      ReferalCode: this.$route.params.referalCode,
      Questionnaire: {
        Id: this.ReferalCode
      },
      Loading: false,
      Referee: {
        Name: String,
        Company: String,
        JobTitle: String,
        Email: String,
        Phone: String,
        ReferenceType: String,
        Pos: Number
      }
    }
  },
  methods: {
    GetQuestionnaireLink() {
      this.Loading=true;
      console.log(this.ReferalCode)
      api.GetQuestionnaireLink(this.ReferalCode, (x) => {
        this.QuestionnaireLink = x;
        api.GetCandidateRefereeByPos(this.QuestionnaireLink.candidateId, this.QuestionnaireLink.refereePos, y=> {
          api.GetQuestionnaire(this.ReferalCode, (z) => {
            this.Referee =y;
            this.Questionnaire = z
            this.Loading=false
          }, ()=>{this.makeToast("Candidate", "Failed to get Candidate Reference check", 'warning')})

        }, ()=>{this.makeToast("Candidate", "Failed to get Candidate Reference check", 'warning')})

      }, ()=>{this.makeToast("Candidate", "Failed to get Candidate Reference check", 'warning')});
    },
    // GetQuestionnaireReferee() {
    //   api.GetCandidateRefereeByPos(this.QuestionnaireLink.candidateId, this.QuestionnaireLink.refereePos, x => {
    //     this.Referee = x;
    //     console.log("Referee", x)
    //   }, console.error)
    // },

    logout() {
      localStorage.clear();
      this.$router.push({path: '/login'});

    },
  },
  created() {
    this.GetQuestionnaireLink();

  }
}
</script>

<style scoped>
.questionnaire-box {
  background-color: #27ef9c;
  /*border-radius: 50px;*/
}

div .base {

  padding: 20px 40px;
  width: 800px;
  margin: 100px auto 0;
}

.navigation {
  top: 0px;
  position: absolute;
  width: 100%;
}

.card {
  padding: 10px 20px;
  background-color: #C0C9D3;
}

.input-group-append {
  padding: 0px;

}
</style>