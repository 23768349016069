<template>
  <div class="home background-img">


    <b-row class="mt-3" id="full-space" >

<!--        <b-img id="Background"  :src="require('./assets/Pattern/PNGs/Repeated Pattern Green-01.png')"></b-img>-->
        <router-view/>

    </b-row>
    </div>
</template>
<script>

export default {

  created() {
    localStorage.setItem("api_url",this.$root.urlConfig.apiUrl)

  }
}
</script>
<style>

#full-space {
  width: 100%;
  height: 100%;
}
#logo {
  height: 5vh;
  padding: 6px 45px;
}

div .base {

  padding: 20px 40px;
  width: 800px;
  margin: 0 auto;
}

nav {
  background-color: #27ef9c;;
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

#Background {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  /*height: 100vh;*/
  /*width: 100vw;*/
  /*overflow: clip;*/
}
.background-img{
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
  background-image: url('./assets/Pattern/PNGs/Repeated Pattern Green-01.png');
  background-repeat: repeat;
  height: 100%;
  width: 100%;
  overflow: scroll;
}
</style>
