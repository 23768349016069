<template>
<div id="RefereeDetailsFull">
  <b-card class="detailsCard">
    <b-card-title>
      {{Referee.name}}
    </b-card-title>
    <b-card-body class="detailsCardBody">
      <b-row>
        <b-col>
          <p>Email: {{Referee.email}}</p>
          <p>Phone: {{Referee.phone}}</p>
        </b-col>
        <b-col>
          <p>Company: {{Referee.company}}</p>
          <p>Job Title/Position: {{Referee.jobTitle}}</p>
        </b-col>
      </b-row>
    </b-card-body>

  </b-card>
</div>
</template>

<script>
export default {
  name: "RefereeDetailsFull",
  props: {
    candidate: {
      id: Number,
      firstName: String,
      lastName: String,
      email: String,
      profilePhoto: String,
    },
    Referee:
      {
        name: String,
        company: String,
        jobTitle: String,
        email: String,
        phone: String,
        referenceType: String
      },
    questionnaires:Object
  },
}
</script>

<style scoped>
#RefereeDetailsFull{
  padding-bottom: 0;
}
.detailsCard{
  padding-bottom: 0;
}.detailsCardBody{
  padding-bottom: 0;
}
</style>