<template>
  <div class="questionnaire">
    <!--    @submit="onSubmit" @reset="onReset"-->
<!--    v-if="!completed"-->
    <b-form v-if="!completed" @submit.stop.prevent="onSubmit" >
      <b-form-group
          id="DurationKnown-group"
          :label="'How long have you known '+QuestionnaireLink.candidateFullName+'?'"
          label-for="DurationKnown"
          description="">
        <b-form-input
            id="DurationKnown"
            v-model="QuestionnaireValue.durationKnown"
            @input='updateValue'
            type="text"
            placeholder="Enter Duration"
            required
            :disabled="view"
        ></b-form-input>
      </b-form-group>
      <b-form-group
          id="ReEmploy-group"
          :label="'During employment was ' + QuestionnaireLink.candidateFullName+' the subject of a disciplinary procedure?'"
          label-for="anyDisciplinaryProcedure"
          @input='updateValue'
          description="">
        <b-form-checkbox
            id="anyDisciplinaryProcedure"
            v-model="QuestionnaireValue.anyDisciplinaryProcedure"
            name="checkbox-1"
            @input='updateValue'
          
            :disabled="view"
            :unchecked-value="false">Yes
        </b-form-checkbox>
        
        <b-form-input
            id="DisciplinaryProcedure"
            v-model="QuestionnaireValue.disciplinaryProcedure"
            @input='updateValue'
            type="text"
            placeholder="Enter Disciplinary Procedure"
            :disabled="view"
            :required="QuestionnaireValue.anyDisciplinaryProcedure"
            v-if="this.QuestionnaireValue.anyDisciplinaryProcedure"

        ></b-form-input>
      </b-form-group>
      <b-form-group
          id="JobPosition-group"
          :label="QuestionnaireLink.candidateFullName+'`s Job Position?'"
          label-for="JobPosition"
          description="">
        <b-form-input
            id="JobPosition"
            v-model="QuestionnaireValue.jobPosition"
            @input='updateValue'
            type="text"
            placeholder="Job Position"
            required
            :disabled="view"
        ></b-form-input>
      </b-form-group>

      <b-form-group
          id="ReEmploy-group"
          :label="'Would you re-employ '+QuestionnaireLink.candidateFullName+'?'"
          label-for="ReEmploy"
          @input='updateValue'
          description="">
        <b-form-checkbox
            id="ReEmploy"
            v-model="QuestionnaireValue.willReEmploy"
            name="checkbox-1"
            @input='updateValue'
            :value="true"
            :unchecked-value="false">Yes
        </b-form-checkbox>
      </b-form-group>
      <b-form-group
          id="GeneralConduct-group"
          label="General Conduct"
          label-for="GeneralConduct"

          description="">
        <b-form-rating id="GeneralConduct"
                       v-model="QuestionnaireValue.generalConduct"
                       @change='updateValue'
                       variant="primary"
                       class="mb-2"
                       required
                       :disabled="view"
        >
        </b-form-rating>
      </b-form-group>
      <b-form-group
          id="WorkPerformance-group"
          label="Work performance"
          label-for="WorkPerformance"
          description="">
        <b-form-rating id="WorkPerformance"
                       v-model="QuestionnaireValue.workPerformance"
                       @change='updateValue'
                       variant="primary"
                       class="mb-2"
                       required
                       :disabled="view"
        >
        </b-form-rating>
      </b-form-group>
      <b-form-group
          id="AttitudeAtWork-group"
          label="Attitude to work"
          label-for="AttitudeAtWork"
          description="">
        <b-form-rating id="AttitudeAtWork"
                       v-model="QuestionnaireValue.attitudeAtWork"
                       @change='updateValue'
                       variant="primary"
                       class="mb-2"
                       required
                       :disabled="view"
        >
        </b-form-rating>
      </b-form-group>
      <b-form-group
          id="Initiative-group"
          label="Initiative"
          label-for="Initiative"
          description="">
        <b-form-rating id="Initiative"
                       v-model="QuestionnaireValue.initiative"
                       @change='updateValue'
                       variant="primary"
                       class="mb-2"
                       required
                       :disabled="view"
        >
        </b-form-rating>
      </b-form-group>
      <b-form-group
          id="TimeKeeping-group"
          label="Time keeping"
          label-for="TimeKeeping"
          description="">
        <b-form-rating id="TimeKeeping"
                       v-model="QuestionnaireValue.timeKeeping"
                       @change='updateValue'
                       variant="primary"
                       class="mb-2"
                       required
                       :disabled="view"
        >
        </b-form-rating>
      </b-form-group>
      <b-form-group
          id="RelationshipWithColleagues-group"
          label="Relationship with Colleagues"
          label-for="RelationshipWithColleagues"
          description="">
        <b-form-rating id="RelationshipWithColleagues"
                       v-model="QuestionnaireValue.relationshipWithColleagues"
                       @change='updateValue'
                       variant="primary"
                       class="mb-2"
                       required
                       :disabled="view"
        >
        </b-form-rating>
      </b-form-group>
      <b-form-group
          id="RelationshipWithCustomers-group"
          label="Relationship with Customers"
          label-for="RelationshipWithCustomers"
          description="">
        <b-form-rating id="RelationshipWithCustomers"
                       v-model="QuestionnaireValue.relationshipWithCustomers"
                       @change='updateValue'
                       variant="primary"
                       class="mb-2"
                       required
                       :disabled="view"
        >
        </b-form-rating>
      </b-form-group>
      <b-form-group
          id="Comments-group"
          label="Any other comments"
          label-for="Comments"
          description="">
        <b-form-textarea
            id="Comments"
            v-model="QuestionnaireValue.comments"
            @input='updateValue'
            rows="3"
            max-rows="5"
            placeholder="Enter Comment"
            :disabled="view"
        ></b-form-textarea>
      </b-form-group>
      <b-button
          type="submit"
          class="submit-user-btn"

          v-if="!view"
      >
        <b-spinner small v-if="loading" variant="primary"></b-spinner>&nbsp;Submit
      </b-button>
    </b-form>
    <div>
      <b-card v-if="completed">
        <b-card-title>Reference check Completed</b-card-title>
        <b-card-body>Thank you for completing the Reference check</b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "WorkQuestionnaire",
  props: {
    Questionnaire: {
      generalConduct: Number,
      durationKnown: String
    },
    QuestionnaireLink:{},
    view:Boolean
  },

  data() {
    return {
      QuestionnaireId: String,
      QuestionnaireValue: {
        generalConduct: null,
        durationKnown: null,
        anyDisciplinaryProcedure:false,
        disciplinaryProcedure:"",
      },
      completed:false,
      loading:false
    }
  },
  methods: {
    onSubmit() {
      this.loading = true
      this.QuestionnaireValue.id = this.QuestionnaireId
      if(this.QuestionnaireValue.anyDisciplinaryProcedure==false){
        this.QuestionnaireValue.disciplinaryProcedure=""
      }

      api.SubmitQuestionnaire(this.QuestionnaireValue, "work", () => {
        this.makeToast("Reference check", "Reference check Submitted", "info")
        this.completed = true
        this.loading=false
      }, () => {
        this.loading=false
        this.makeToast("Reference check", "Reference check submission Failed", "warning")
      })

    },
    onReset() {

    },
    updateValue() {
      this.$emit('update:modelValue', this.QuestionnaireValue);

    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(`${message}`, {
        title: title,
        variant: variant,
        autoHideDelay: 5000,
        appendToast: false,
        noCloseButton: true
      })
    },
  },
  created() {
    this.QuestionnaireId = this.$route.params.referalCode;
    if (this.view===true){
      this.QuestionnaireId = this.QuestionnaireLink.questionnaireId
      api.GetQuestionnaire(this.QuestionnaireId,x=>{this.QuestionnaireValue=x},() => {

        this.makeToast("Reference check", "Failed to load Reference check", "warning")
      })
      return
    }
    this.QuestionnaireValue.generalConduct = ""
    this.QuestionnaireValue.durationKnown = ""
    this.QuestionnaireValue.jobPosition="";
    this.QuestionnaireValue.willReEmploy=false;
    this.QuestionnaireValue.anyDisciplinaryProcedure=false;
    this.QuestionnaireValue.disciplinaryProcedure="";
    this.QuestionnaireValue.generalConduct = "";
    this.QuestionnaireValue.workPerformance =  "";
    this.QuestionnaireValue.attitudeAtWork = "";
    this.QuestionnaireValue.initiative = "";
    this.QuestionnaireValue.timeKeeping =  "";
    this.QuestionnaireValue.relationshipWithColleagues =  "";
    this.QuestionnaireValue.relationshipWithCustomers =  "";
    this.QuestionnaireValue.id = this.QuestionnaireId

  }
}
</script>
<style>
output {
  border: none !important;
  margin: 2px 5px;
  width: 197px !important;
}
div{
  padding: 5px 0px;
}
</style>